import Paternidad from "@mui/icons-material/BabyChangingStation";
import Teatro from "@mui/icons-material/TheaterComedy";
import Balance from "@mui/icons-material/Balance";
import Man from "@mui/icons-material/Man";
import Street from "@mui/icons-material/Signpost";
import GradientSvg from "./GradientSvg";
import GirlIcon from "@mui/icons-material/Girl";
import TreeIcon from "@mui/icons-material/Park";
import React from "react";

const CategoriesIcons = ({ icon }) => {
  const gavelSvg = [
    "M 183.309 96.194 L 191.62 87.226 L 233.125 132.116 L 224.815 141.084 L 183.309 96.194 Z M 195.749 82.738",
    "M 170.829 109.661 L 179.136 100.689 L 195.749 118.636 L 187.441 127.608 L 170.829 109.661 Z",
    "M 176.193 137.217 L 211.433 137.217 L 211.433 143.562 L 170.829 143.562 L 176.193 137.217 Z",
    "M 195.584 83.665 L 203.891 74.693 L 220.504 92.64 L 212.196 101.611 L 195.584 83.665 Z",
  ];
  const svgClases = "display-4 fw-bold py-4 d-flex justify-content-center";
  switch (icon) {
    case "Girls":
      return (
        <div className={svgClases}>
          <GirlIcon
            color="success"
            fontSize={"large"}
            style={{
              height: 150,
              width: 150,
              position: "absolute",
              marginRight: "50px",
            }}
          />
          <GirlIcon
            color="warning"
            fontSize={"large"}
            style={{ height: 150, width: 150, marginLeft: "50px" }}
          />
        </div>
      );
    case "Street":
      return (
        <div className={svgClases}>
          <Street
            color="success"
            fontSize={"large"}
            style={{ height: 150, width: 150 }}
          />
        </div>
      );
    case "Gavel":
      return (
        <GradientSvg
          path1={gavelSvg[0]}
          path2={gavelSvg[1]}
          path3={gavelSvg[2]}
          path4={gavelSvg[3]}
        />
      );
    case "Paternal":
      return (
        <div className={svgClases}>
          <Paternidad
            color="error"
            fontSize={"large"}
            style={{ height: 150, width: 150 }}
          />
        </div>
      );
    case "Theater":
      return (
        <div className={svgClases}>
          <Teatro
            className="bi"
            fontSize="large"
            style={{ height: 150, width: 150 }}
          />
        </div>
      );
    case "Balance":
      return (
        <div className={svgClases}>
          <Balance
            fontSize="large"
            style={{ height: 150, width: 150, color: "#34aaaf" }}
          />
        </div>
      );
    case "Tree":
      return (
        <div className={svgClases}>
          <TreeIcon
            fontSize="large"
            color="success"
            style={{ height: 150, width: 150 }}
          />
        </div>
      );
    case "Man":
      return (
        <div className={svgClases}>
          <Man fontSize="large" style={{ height: 150, width: 150,  position: "absolute", marginRight: "100px" }} />
          <Man fontSize="large" style={{ height: 150, width: 150, marginLeft: "50px" }} />
        </div>
      );

    default:
      return null;
  }
};

export default CategoriesIcons;
