function GradientSvg(props){
    const svgClases = "display-4 fw-bold py-4 d-flex justify-content-center";
    const gradientStyles = {
        gradiente1: {
          fill: "url(#gradiente1)",
        },
        gradiente2: {
          fill: "url(#gradiente2)",
        },
        gradiente3: {
          fill: "url(#gradiente3)",
        },
        gradiente4: {
          fill: "url(#gradiente4)",
        }
      };
    return(
        <div className={svgClases}>
        <svg
          viewBox="170.829 74.693 62.296 68.869"
          width={150}
          height={150}
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id="gradiente1"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop
                offset="25%"
                style={{ stopColor: "#02592c", stopOpacity: 1 }}
              />
              <stop
                offset="50%"
                style={{ stopColor: "#047836", stopOpacity: 1 }}
              />
            </linearGradient>

            <linearGradient
              id="gradiente2"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop
                offset="25%"
                style={{ stopColor: "#ca820f", stopOpacity: 1 }}
              />
              <stop
                offset="75%"
                style={{ stopColor: "#fbb810", stopOpacity: 1 }}
              />
            </linearGradient>

            <linearGradient
              id="gradiente3"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop
                offset="25%"
                style={{ stopColor: "#a8021d", stopOpacity: 1 }}
              />
              <stop
                offset="50%"
                style={{ stopColor: "#f60427", stopOpacity: 1 }}
              />
            </linearGradient>

            <linearGradient
              id="gradiente4"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop
                offset="15%"
                style={{ stopColor: "#06b4b6", stopOpacity: 1 }}
              />
              <stop
                offset="95%"
                style={{ stopColor: "#ADD8E6", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <path
            d={props.path1}
            style={gradientStyles.gradiente4}
          />
          <path
            d={props.path2}
            style={gradientStyles.gradiente3}
          />
          <path
            d={props.path3}
            style={gradientStyles.gradiente2}
          />
          <path
            d={props.path4}
            style={gradientStyles.gradiente1}
          />
        </svg>
      </div>

    );
      
}

export default GradientSvg;