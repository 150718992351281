import './css/inter-300.css';
import {Routes, Route} from 'react-router-dom';
import React from 'react';
import HomePage from './components/HomePage.js';
import Biografia from './components/Biografia.js';
import ScrollToTopButton from './components/ScrollToTopButton.js';
import ThankYouRegisteredPage from './components/ThankYouRegisteredPage.js';
import ThankYouForDonatePage from './components/ThankYouForDonatePage.js';
import NotFound from './components/NotFound.js';
import PodcastPage from './components/PodcastPage.js';
import ScrollbarStyles from './components/ScrollBarStyles.js';
import Commitments from './components/Commitments.js';

function App() {
  return (
    <>
      <ScrollToTopButton />
      <ScrollbarStyles />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/biografia" element={<Biografia />} />
        <Route path="/thank-you-for-get-registred" element={<ThankYouRegisteredPage />} />
        <Route path="/thank-you-for-donate" element={<ThankYouForDonatePage />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/podcast" element={<PodcastPage />} />
        <Route path="/commitments" element={<Commitments />} />
      </Routes>
    </>
  );
};

export default App;
