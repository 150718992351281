import React from "react";
import { useState } from "react";
import ArrowUp from '@mui/icons-material/ArrowUpward';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  window.addEventListener("scroll", () => {
    setVisible(document.documentElement.scrollTop > 0);
  });

  const irArriba = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      className="ir-arriba rounded-circle btn shadow"
      aria-label="Te lleva a la parte frontal de la página"
      onClick={irArriba}
      data-aos="fade-up"
      
      style={{
        display: visible ? "block" : "none",
        backgroundColor: "rgb(246, 166, 17)",
        borderColor: "green"
      }}
    >
      <ArrowUp style={{fill: "#af031d"}}/>
    </button>
  );
};

export default ScrollToTopButton;
