import React from "react";
import content from "../cms/content.json";
import SocialNetworks from "./SocialNetworks";
function Footer() {
  return (
    <footer
      className="bg-body-tertiary text-center"
      style={{
        background:
          "linear-gradient(46deg, rgb(72 24 11) 29%, rgb(78, 50, 42))",
      }}
    >
      <img
        src="img/footer.png"
        width={600}
        alt="José Horacio Rodríguez, Diputado del Distrito Nacional, República Dominicana."
        className="img-fluid"
      />
      <div className="text-center p-3">
        <p className="text-white">
          {content.titles.footer} {content.titles.name}.
        </p>
        <div className="d-flex justify-content-center">
          <SocialNetworks />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
