import { Button } from "@mui/material";
import React from "react";
import Telephone from "./Telephone";
function Voluntariado() {
  return (
    <>
      <div
        className="container py-vh-3 aos-init aos-animate"
        data-aos="fade"
        data-aos-delay="200"
        id="voluntariado"
      >
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-8 text-center">
            <h3 className="fs-2 fw-light">
              !Regístrate como{" "}
              <span className="fw-bold text-success">voluntario </span>y ayúdame
              a ayudar!
            </h3>
            <hr />
          </div>
        </div>
        <div className="container mb-3">
          <div className="col-lg-10 offset-lg-1">
            <div
              className="col-12 border p-5 bg-white rounded shadow"
              data-aos="fade"
            >
              <div className="d-flex justify-content-center p-3 mb-5">
                <img
                  src="img/H-logo.png"
                  alt="Logo de Jose Horacio1"
                  width="64"
                />
              </div>
              <form
                id="sib-form"
                method="POST"
                action="https://cb97d59f.sibforms.com/serve/MUIFACPT8iWVF5URa8EIlIf6y5wzvMxrpBKn2uGtPNAaFoJy-Fh02N_cSVzL5NzUken8tX1dSCcLbBPUV3Q0GEuAeMqJ2h5_pGJed6p4sUxiYLvYvqFW0jqF2b8eGyzSib_ZJygTLUKSVlUaajvbS1_b07sgrusYGfI-Sn8Ee6Tw3hA1-ZgCsMjHyDXdP64kOKalBf1vdQqky1QS"
                data-type="subscription"
              >
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="NOMBRE"
                      name="NOMBRE"
                      autoComplete="off"
                      placeholder="Nombre"
                      data-required="true"
                      required
                    />
                    <label htmlFor="NOMBRE">Nombre</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      id="APELLIDOS"
                      name="APELLIDOS"
                      autoComplete="off"
                      placeholder="Apellidos"
                    />
                    <label htmlFor="APELLIDOS">Apellidos</label>
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="email"
                      id="EMAIL"
                      name="EMAIL"
                      autoComplete="off"
                      placeholder="Correo electrónico"
                      data-required="true"
                      required
                    />
                    <label htmlFor="EMAIL">Correo electrónico</label>
                  </div>
                  <Telephone />
                  <div className="d-grid">
                    <Button
                      className="btn btn-primary btn-lg mt-4 btn-xl fw-light aos-init aos-animate"
                      type="submit"
                      color="success"
                      variant="contained"
                      style={{
                        borderRadius: 0,
                        height: 62,
                        textTransform: "none",
                      }}
                    >
                      ¡Regístrate!
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Voluntariado;
