import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import IconButton from "@mui/material/IconButton";
import content from "../cms/content.json";
import FacebookIcon from "@mui/icons-material/Facebook";

function SocialNetworks() {
  return (
    <div className="d-flex">
      <div className="p-1">
        <IconButton
          href={content.social.instagram}
          target="_blank"
          aria-label="Cuenta de Instagram de Horacio"
          style={{
            background:
              "linear-gradient( to right, #f09433 0%, #ec6843 25%, #e94b6d 50%, #e62e99 75%, #dc143c 100% )",
            width: "28px",
            height: "28px"
          }}
        >
          <InstagramIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      <div className="p-1">
        <IconButton
          href={content.social.facebook}
          target="_blank"
          aria-label="Cuenta de Facebook de Horacio"
          style={{
            backgroundColor: " #3b5998",
            width: "28px",
            height: "28px"
          }}
        >
          <FacebookIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      <div className="p-1">
        <IconButton
          href={content.social.twitter}
          target="_blank"
          aria-label="cuenta de Twitter de Horacio"
          style={{
            background: "black",
            width: "28px",
            height: "28px",
          }}
        >
          <XIcon style={{ color: "white", fontSize: "18px" }} />
        </IconButton>
      </div>
      <div className="p-1">
      <IconButton
          href={content.social.linkedin}
          target="_blank"
          aria-label="Cuenta de LinkedIn de Horacio"
          style={{
            width: "28px",
            height: "28px",
          }}
        >
        <LinkedInIcon color="primary" style={{fontSize: "30px"}} />
        </IconButton>
      </div>
      <div className="p-1">
      <IconButton
        href={content.social.youtube}
        target="_blank"
        aria-label="Cuenta de Youtube de Horacio"
        style={{
          width: "28px",
          height: "28px",
        }}
      >
        <YouTubeIcon color="error" style={{fontSize:"36px"}} />
      </IconButton>
      </div>
    </div>
  );
}

export default SocialNetworks;
