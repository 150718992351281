import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/theme.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import  {BrowserRouter} from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import content from "./cms/content.json";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
console.log(content.titles.homeText)
console.log("Developed by José Ismael De óleo Pereyra @soyjoseismael");
console.log("Hola mundo!");
reportWebVitals();
