import content from "../cms/content.json";
const HomeVideo = () => {
  return (
    <div
      className="col-12 col-lg-8 mt-0 h-100 position-absolute top-0 end-0 bg-cover fondo-rayas"
      data-aos="fade-left"
      style={{ backgroundImage: `url(${content.images.homeBackgroundImage})` }}
    ></div>
  );
};

export default HomeVideo;
