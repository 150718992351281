import React from "react";
import content from "../cms/content.json";
import TopNav from "./TopNav";
import Footer from "./Footer";

function Commitments() {
  const commitments = content.commitments;
  const romanNumber = commitments.reduce((acc, commitment) => {
    if (!acc.hasOwnProperty(commitment.roman)) {
      acc[commitment.roman] = [];
    }
    acc[commitment.roman].push(commitment);
    return acc;
  }, {});
  const groupByRoman = Object.keys(romanNumber);
  return (
    <>
      <TopNav isFixedTop={false} showLinks={false} />
      <main>
        <div
          className="wrapper"
          style={{
            backgroundImage: `url(${content.images.homeBackgroundImage2})`,
            backgroundSize: "cover",
            backgroundstep: "center",
          }}
          data-aos="fade-down"
        >
          <div
            className="overlay py-vh-6"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div
                  className="col-12 col-xl-8 text-center"
                  data-aos="fade-left"
                >
                  <div className="text-primary mb-3">
                    <img
                      src={content.images.hLogo}
                      alt="MAS JOSE HORACIO"
                      width="100"
                    />
                  </div>
                  <h1 className="fw-bold display-6 text-white">
                    {" "}
                    ¿A qué vuelvo a la Cámara de Diputados?
                  </h1>
                  <p
                    className="lead fw-bold"
                    style={{
                      color: "#fbb411",
                    }}
                  >
                    Compromisos legislativos para el 2024
                  </p>
                  <p
                    style={{
                      fontStyle: "italic",
                    }}
                    className="text-white"
                  >
                    Por José Horacio Rodríguez.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="small py-vh-3 w-100 overflow-hidden bg-light"
          id="steps"
        >
          <div className="container">
            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 py-vh-3 pb-0">
              {groupByRoman.map((roman) => (
                <div key={roman} className="row">
                  <h2 className="py-vh-2">
                    <strong>{roman}.</strong> {romanNumber[roman][0].title}
                  </h2>
                  {romanNumber[roman].map((commitment) => (
                    <div
                      className="aos-init aos-animate"
                      data-aos={commitment.effect}
                    >
                      <p className="fw-bold lead">{commitment.subTitle}</p>
                      <p class="lead">{commitment.description}</p>
                      {commitment.stepsArray &&
                        commitment.stepsArray.map((step, index) => (
                          <div
                            className="col-11 aos-init aos-animate"
                            data-aos="fade-up"
                            key={index}
                          >
                            <div className="d-flex">
                              <div className="col-md-3 flex-fill pt-3 pe-3 pe-md-0">
                                <img
                                  className="img-fluid"
                                  src={step.image}
                                  alt={step.title}
                                  width={step.width}
                                />
                              </div>
                              <div className="col-md-9 flex-fill">
                                <h3 className="h5 my-2">{step.title}</h3>
                                <p>{step.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>

                    //if steps is an array, map it and return a <p> tag with the description
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default Commitments;
