import NavLink from "./NavLink";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import content from "../cms/content.json";
import SocialNetworks from "./SocialNetworks";


function TopNav({ isFixedTop, showLinks = true}) {
  const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 50); 
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navClasses = [
    "navbar",
    "navbar-expand-lg",
    "navbar-light",
    isFixedTop ? "fixed-top" : "",
    isScrolled ? "scrolled shadow-sm" : "",
  ].join(" ");

  const mainLinkClasses = [
      "ms-1",
      "fw-bolder",
    ].join(" ");

  const linkClasses = [
    "collapse",
    "navbar-collapse",
    showLinks ? "" : "justify-content-end",
  ].join(" ");

  return (
    <nav
      id="navScroll"
      className={navClasses}
      tabIndex={0}
    >
      <div className="container">
        <Link className="navbar-brand pe-4 fs-4" to="/">
          <img src={content.images.hLogo} alt="MAS JOSE HORACIO" width="50" />
          <span className={mainLinkClasses}>José Horacio</span>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={linkClasses} id="navbarSupportedContent">
          {showLinks &&
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <NavLink goTo="#biografia" name="Biografía" ariaLabel="Biografía de José Horacio Rodriguez" />
            <NavLink goTo="#achievements" name="Logros" ariaLabel="Trabajos legislativos de José Horacio durante estos años" />
            <NavLink goTo="#positions" name="Posiciones firmes" ariaLabel="Posiciones fijadas durante estos años por José Horacio Rodriguez" />
            <NavLink goTo="#podcast" name="Podcast" ariaLabel="Mantente informado con las novedades sobre los podcast de José Horacio, Diputado del Distrito nacional." />
            <NavLink goTo="#rendicion" name="Rendición de cuentas" ariaLabel="Rendición de cuentas de José Horacio" />
           
            <NavLink
              goTo="#voluntariado"
              name="Voluntariado"
              ariaLabel="Regístrese como voluntario" 
            />
          </ul>}
          <SocialNetworks />
        </div>
      </div>
    </nav>
  );
}
export default TopNav;
