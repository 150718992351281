import React from "react";
import { Link } from "react-router-dom";
import content from "../cms/content.json";
import "../css/biography-page.css";
import TopNav from "./TopNav";
import Footer from "./Footer";

function Biografia() {
  const biography = content.biography;
  const titles = biography.reduce((acc, paragraph) => {
    if(!acc.hasOwnProperty(paragraph.title)) {
      acc[paragraph.title] = [];
    }
    acc[paragraph.title].push(paragraph);
    return acc;
  }, {});
  const groupByTitle = Object.keys(titles);
  const alt =
    "Biografía de José Horacio Rodríguez, Diputado del Distrito Nacional, República Dominicana.";
  const buttonName = "Volver a inicio";
  return (
    <>
      <TopNav isFixedTop={false} showLinks={false} />
      <main>
        <div className="py-vh-2 w-100 overflow-hidden" id="services">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <h1 className="display-huge fw-bold pt-3 mt-5" data-aos="fade-down">
                  {content.titles.name}
                  <hr />
                </h1>
              </div>
              <div
                className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 py-vh-2"
                data-aos="fade-left"
              >
                {groupByTitle.map((title) => (
                  <div key={title}>
                    <h2 className="lead fw-bold py-3">{title}</h2>
                    {titles[title].map((paragraph) => (
                      <p className="lead">
                        {paragraph.description}
                      </p>
                    ))}
                  </div>
                ))}
                <Link to="/" className="btn btn-primary">
                  {buttonName}
                </Link>
              </div>
              <div
                className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 p-0 aos-init aos-animate"
                data-aos="fade-up"
              >
                <img
                  src={content.images.biographyHero2}
                  className="img-fluid float-start"
                  alt={alt}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Biografia;
