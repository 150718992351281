import React, { useEffect, useState } from "react";
import axios from "axios";
import ButtonBaseDemo from "./ButtonBaseDemo";
import content from "../cms/content.json";
import { Button } from "@mui/material";
function YouTubeVideos() {
  const [videos, setVideos] = useState([]);
  const title = content.titles.podcast;

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const API_KEY = "AIzaSyB_8K1lv_A8eJAMjIfnUmmjm-Ee4EsHOR8";
        const CHANNEL_ID = "UCMh8ApjdZfdH4Jks58SgfoA";
        const API_URL = `https://www.googleapis.com/youtube/v3/search?order=date&part=snippet&channelId=${CHANNEL_ID}&maxResults=6&key=${API_KEY}`;
        const response = await axios.get(API_URL);
        setVideos(response.data.items);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div
      className="position-relative overflow-hidden w-100 bg-light"
      id="podcast"
    >
      <div className="container-fluid" style={{ backgroundColor: "#421d0f" }}>
        <div className="row">
          <div className="col-12">
            <div className="justify-content-center py-vh-3 d-flex col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <h2 className="display-6 text-white">
                {" "}
                <span
                  style={{
                    color: "#f0a500",
                    fontWeight: "bold",
                  }}
                >
                  {title[0]}
                </span>
                {title.substring(1, title.length)}{" "}
                <span
                  style={{
                    color: "#f0a500",
                    fontWeight: "bold",
                    fontStyle: "italic",
                  }}
                >
                  {content.titles.podcast_1}
                </span>
                <hr className="shadow"></hr>
              </h2>
            </div>
            <div className="row justify-content-center vw-100 px-0 pb-5 d-flex align-items-center">
              <ButtonBaseDemo images={videos} />
            </div>
            <div className="row">
              <p className="text-white text-center"
                  style={{
                    fontWeight: "200",
                    fontSize: "26px",
                    lineHeight: "1.5",
                  }}>
                  ¡Escucha el podcast en tu plataforma favorita!
                </p>       
              <div className="justify-content-center d-flex"> 
                <Button
                  className="aos-init btn btn-dark shadow aos-animate mx-5 me-3 rounded-0 my-5"
                  style={{ height: 62, textTransform: "none", left: "8px"}}
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      viewBox="0 0 16 16"
                      style={{ fill: "white" }}
                    >
                      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.669 11.538a.5.5 0 0 1-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 0 1-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 0 1 .166.686m.979-2.178a.624.624 0 0 1-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 0 1-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 0 1 .206.858m.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 1 1-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 1 1-.764 1.288" />
                    </svg>
                  }
                  variant="contained"
                  size="large"
                  color="success"
                  href="https://open.spotify.com/show/7esDTkqTyZV8AuTZEibisD?si=u_aFnVsPQWqn4ZIM4QGs0Q"
                  target="_blank"
                  rel="norrefer"
                  data-aos="fade-down"
                >
                  Escúchalo en Spotify
                </Button>
                <Button
                  className="aos-init btn btn-dark shadow aos-animate mx-5 me-3 rounded-0 my-5"
                  style={{ height: 62, textTransform: "none", left: "8px"}}
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="white"
                      viewBox="0 0 16 16"
                      style={{ fill: "white" }}
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z" />
                    </svg>
                  }
                  variant="contained"
                  size="large"
                  color="error"
                  href="https://www.youtube.com/watch?v=UHol9B1f76o&list=PLxwCsPP9D2p0iITHyFSt3GxVxN4G9cDUM&index=5"
                  target="_blank"
                  rel="norrefer"
                  data-aos="fade-down"
                >
                  Escúchalo en Youtube
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YouTubeVideos;
