import React from "react";
import {deviceDetect} from "react-device-detect";

const ScrollbarStyles = () => {
  if (deviceDetect.isWindows) {
    console.log("Estamos usando windows");
    return (
        <>
        <style>
            {`
            ::-webkit-scrollbar {
                width: 7px;
            }
            ::-webkit-scrollbar-track {
                background: #cac3cb;
            }
            ::-webkit-scrollbar-thumb {
                background-color: #888;
                border-radius: 6px;
            }
            ::-webkit-scrollbar-thumb:hover {
                background-color: #555;
            }
          `}
        </style>
        </>
    );
  }

  return null;
};

export default ScrollbarStyles;
