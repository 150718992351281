import React from "react";
import { Link } from "react-router-dom";
import content from "../cms/content.json";
import styles from "../css/bio-hero.module.css";

function BiografiaSection() {
  const resena = content.resena;
  const buttonText = "Ver biografía";
  return (
    <div className="py-vh-5 w-100 overflow-hidden" id="biografia">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div
            className="col-md-6 col-lg-4 biography-hero-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <img
              src={content.images.biographyHero}
              alt="José Horacio Rodríguez"
              className={`${styles["biography-hero__horacio"]} img-fluid aos-init`}
            />
          </div>
          <div
            className="col-md-6 col-lg-8 pb-0"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <div className="col-12 col-lg-10 text-start">
              <h2 className="pb-3">
                <hr
                  className="shadow"
                  data-aos="fade-down"
                  data-aos-delay="400"
                />
                {content.titles.biography}
              </h2>
              {resena.map((paragraph) => (
                <p
                  className="pt-1 mt-1 lead text-dark"
                  data-aos="fade-up"
                  key={paragraph.id}
                  style={{ textAlign: "justify" }}
                >
                  {paragraph.description}
                </p>
              ))}
              <Link
                to="/biografia"
                className="btn btn-primary shadow"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                {buttonText}
              </Link>
              <hr className="shadow" data-aos="fade-up" data-aos-delay="400" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BiografiaSection;
