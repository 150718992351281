import React, { useState } from "react";

function Telephone() {
  const [codigoArea, setCodigoArea] = useState("");
  const [numeroTelefono, setNumeroTelefono] = useState("");

  const handleCodigoAreaChange = (event) => {
    setCodigoArea(event.target.value);
  };

  const handleNumeroTelefonoChange = (event) => {
    setNumeroTelefono(event.target.value);
  };

  // const getTelefonoCompleto = () => {
  //   return `${codigoArea}${numeroTelefono}`;
  // };
  return (
    <div>
      <label className="mb-2">Whatsapp:</label>
      <div className="d-flex">
        <div className="col-auto form-floating">
          <select
            id="code"
            name="SMS__COUNTRY_CODE"
            className="form-control"
            data-required="true"
            value={codigoArea}
            onChange={handleCodigoAreaChange}
            required
            aria-required="true"
          >
            <option value=""></option>
            <option value="+1809">+1 (809)</option>
            <option value="+1829">+1 (829)</option>
            <option value="+1849">+1 (849)</option>
          </select>
          <label htmlFor="code">Code</label>
        </div>
        <div className="col form-floating">
          <input
            id="SMS"
            name="SMS"
            type="tel"
            maxLength={7}
            minLength={7}
            className="form-control"
            autoComplete="off"
            data-required="true"
            required
            value={numeroTelefono}
            onChange={handleNumeroTelefonoChange}
          />
          <label htmlFor="SMS">
            Escribe tu número sin guiones ni espacios, ejemplo: 1231234
          </label>
          {/* <input type="hidden" autocomplete="off" id="TELEFONO" name="TELEFONO" value={getTelefonoCompleto()} /> */}
        </div>
      </div>
    </div>
  );
}

export default Telephone;
