import React from "react";
import { Link } from "react-router-dom";
import SocialNetworks from "./SocialNetworks";
function NotFound() {
  return (
    <div className="container py-vh-5">
    <div className="col-lg-10 offset-lg-1">
      <div
        className="col-12 border p-5 bg-white rounded shadow"
        data-aos="fade"
      >
        <div className="d-flex justify-content-center p-3 mb-5">
          <img
            src="img/H-logo.png"
            alt="Logo de Jose Horacio"
            width="65"
          />
        </div>
          <div className="col-12">
           <h1 className="text-center">¡Ups!</h1>
           <h3 className="text-center">La página que buscas no pudo ser localizada</h3>
           <hr />
           <p className="text-center lead">Recuerda que puedes seguirme en mis redes sociales:</p>
           <div className='d-grid justify-content-center'>
             <SocialNetworks  />
           </div>      
            <div className="d-grid">
              <Link
                className="btn btn-primary btn-lg mt-4 btn-xl fw-light aos-init aos-animate"
                to="/"
                type="submit"
                variant="contained"
                style={{
                  borderRadius: 0,
                  height: 62,
                  textTransform: "none",
                }}
              >
                Vuelve a la página principal
              </Link>
            </div>
          </div>
      </div>
    </div>
  </div>
  );
}

export default NotFound;