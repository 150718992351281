import React from "react";
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";
import content from "../cms/content.json";
function Rendiion() {
  return (
    <div className="py-vh-4 bg-gray-100 w-100 overflow-hidden" id="rendicion">
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-lg-6">
            <div className="row gx-5 d-flex">
              <div className="col-md-11">
                <div
                  className="shadow ratio ratio-16x9 rounded bg-cover bp-center align-self-end aos-init aos-animate"
                  data-aos="fade-right"
                  style={{ backgroundImage: `url(${content.images.rendicion5})`, "--bs-aspect-ratio": "60%" }}
                ></div>
            
              </div>
              <div className="col-md-5 offset-md-1">
                <div
                  className="shadow ratio ratio-1x1 rounded bg-cover mt-5 bp-center float-end aos-init"
                  data-aos="fade-up"
                  style={{ backgroundImage: `url(${content.images.rendicion3})`}}
                ></div>
              </div>
              <div className="col-md-6">
                <div
                  className="col-12 shadow ratio rounded bg-cover mt-5 bp-center aos-init"
                  data-aos="fade-left"
                  style={{ backgroundImage: `url(${content.images.rendicion1})`, "--bs-aspect-ratio": "150%"}}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h3
              className="py-5 aos-init"
              data-aos="fade-left"
            >
              <hr className="shadow"></hr>
              {content.titles.rendicion}
            </h3>
            <p data-aos="fade-left" data-aos-delay="200" className="aos-init">
              {content.titles.rendicion2}
            </p>
            <Button data-aos="fade-left" data-aos-delay="200" style={{borderRadius: 0}} variant="contained" className="mt-3 aos-init shadow btn btn-dark" href={content.pdf.pdf1} download={content.pdf.pdf1} color="warning" endIcon={<DownloadIcon />}>
              {content.titles.rendicion3}
            </Button>
            <Button data-aos="fade-left" data-aos-delay="200"  style={{borderRadius: 0}}  variant="contained" className="mt-3 aos-init shadow btn btn-dark" href={content.pdf.pdf2} download={content.pdf.pdf2} color="success" endIcon={<DownloadIcon />}>
              {content.titles.rendicion4}
            </Button>
            <Button data-aos="fade-left" data-aos-delay="200"  style={{borderRadius: 0}}  variant="contained" className="mt-3 aos-init shadow btn btn-dark" href={content.pdf.pdf3} download={content.pdf.pdf3} color="error" endIcon={<DownloadIcon />}>
              {content.titles.rendicion5}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rendiion;
