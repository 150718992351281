import React from "react";
import content from "../cms/content.json";
function Gallery() {
  const fotos = content.gallery.filter((foto) => foto.categoria !== "congres");
  return (
    <div
      className="position-relative overflow-hidden w-100"
      id="gallery"
    >
      <div className="container-fluid">
        <div className="row" style={{overflowX: 'scroll', overflowY: 'hidden'}}>
          <div className="col-12">
            <div className="row vw-100 px-0 py-vh-5 d-flex align-items-center scrollx" style={{flexWrap: "nowrap"}}>
              {fotos.map((foto) => (  
                    <div className={foto.size} data-aos="fade-up" data-aos-delay={foto.delay} key={foto.id}>
                    <img
                      src={foto.src}
                      className="rounded shadow img-fluid"
                      alt={foto.alt}
                      width={foto.width}
                      height={foto.height}
                    />
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
