import HomeVideo from "./HomeVideo";
import HomeInfo from "./HomeInfo";
import React from 'react';

function Home() {
   const className = "container position-relative";
  return (
      <div className="w-100 bg-gray-100" id="top">
        <div className={className}>
          <HomeVideo/>
          <HomeInfo />  
        </div>        
      </div>  
  );
}

export default Home;