import React from "react";
import content from "../cms/content.json";
import "../css/svg-colors.css";

function FixedPositions() {
    const positions = content.fixedPositions;
  return (
    <div className="small py-vh-3 w-100 overflow-hidden bg-light" id="positions">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-12 text-center mb-5 pb-5">
            <hr className="shadow"></hr>
            <h2 className="display-6">{content.titles.fixedPositions1}</h2>
            <p className="lead">{content.titles.fixedPositions2}</p>
          </div>
          {positions.map((position, index) => (
            <div
              className="col-md-6 col-lg-4 border-end aos-init aos-animate"
              data-aos="fade-up"
              key={index}
            >
              <div className="d-flex">
                <div className="col-md-3 flex-fill pt-3 pe-3 pe-md-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    fill="currentColor"
                    className={position.boostrapIcon}
                    viewBox="0 0 16 16"
                  >
                    <path d={position.path1}/>
                    <path d={position.path2}/>
                    <path d={position.path3}/>
                    <path d={position.path4}/>
                  </svg>
                </div>
                <div className="col-md-9 flex-fill">
                  <h3 className="h5 my-2">{position.title}</h3>
                  <p>
                    {position.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FixedPositions;
