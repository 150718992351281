import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

function TransferenciaButton() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className="btn btn-dark aos-init aos-animate me-3 mt-4 "
        variant="contained"
        onClick={handleClickOpen}
        style={{borderRadius: 0, height:62, textTransform: "none"}}
      >
        Vía transferencia
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Realizar donación vía transferencia bancaria"}
        </DialogTitle>
        <DialogContent>
          <div className="container">
            <div className="row">
              <div className="col-12  py-vh-3">
                <div className="row d-flex">
                  <h4 className="mt-3">Banreservas</h4>
                  <div className="text-center">
                    <img
                      src="img/ws/banco.png"
                      className="img-fluid"
                      alt="transferencia"
                      style={{ width: "120px", height: "auto" }}
                    />
                  </div>
                </div>
              </div>

              <p>
                Realiza tu donación a través de transferencia bancaria a la
                cuenta <span className="fw-bold">de ahorros</span> número:
                <mark className="fw-bold">9606479893</mark>, a nombre de José
                Horacio Rodríguez Grullón, cédula: <mark className="fw-bold">001-1816605-7</mark>.
              </p>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TransferenciaButton;
