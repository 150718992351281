import React from "react";
import content from "../cms/content.json";
import homeStyles from "../css/home-hero.module.css";
import logoStyles from "../css/h-logo.module.css";
import { Link } from "react-router-dom";


function HomeInfo() {
  const classNameHero = `${homeStyles["home-hero"]} ${homeStyles["home-sm-hero"]} ${homeStyles["home-md-hero"]} ${homeStyles["home-lg-hero"]} responsive-jh aos-init`;
  const classNameLogo = `${logoStyles["h-sm-logo"]} ${logoStyles["h-md-logo"]} ${logoStyles["h-lg-logo"]} fluid-img`;
  const classNameText = `${logoStyles["home-sm-text"]} ${logoStyles["home-md-text"]} mt-3`;
  return (
    <div className="row">
      <div className="col-lg-7 py-vh-6 position-relative" data-aos="fade-right">
        <div className="row">
          <img
            src="img/slogan.png"
            alt="MÁS BUENA POLÍTICA"
            className={classNameLogo}
          />
          <div className={classNameText}>
            <p className="lead mx-5" style={{ marginBottom: "0px" }}>
              {" "}
              {content.titles.homeText}
            </p>
            <p
              className="lead mx-5"
              style={{ fontStyle: "italic", fontSize: "large" }}
            >
              {" "}
              {content.titles.homeText2}
            </p>
          </div>
        </div>
        <a
          href="#donate"
          className="btn btn-dark btn-xl shadow mx-5 me-3 rounded-0 my-5"
        >
          Contribuye
        </a>
        <Link to="/commitments" className="btn btn-warning btn-xl text-white shadow rounded-0">
                Compromisos para el 2024
        </Link>
      </div>
      <div
        className="col-lg-5 position-relative home-hero-container"
        data-aos="ease"
        style={{ display: "grid" }}
      >
        <img
          src={content.images.homeHero}
          alt="MAS JOSE HORACIO"
          className={classNameHero}
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
          style={{ alignSelf: "end" }}
        />
      </div>
    </div>
  );
}

export default HomeInfo;
