import React from "react";
import Home from "./Home";
import BiografiaSection from "./BiografiaSection";
import Rendicion from "./Rendicion";
import Achievements from "./Achievements";
import FixedPositions from "./FixedPositions";
import Gallery from "./Gallery";
import Donate from "./Donate";
import Voluntariado from "./Voluntariado";
import Footer from "./Footer";
import PodCastPage from "./PodcastPage";
import TopNav from "./TopNav";
function HomePage() {
  return (
    <>
      <TopNav isFixedTop={true} />
      <main>
        <Home />
        <BiografiaSection />
        <Achievements />
        <Gallery />
        <FixedPositions />
        <PodCastPage />
        <Rendicion />
        <Donate />
        <Voluntariado />
        <Footer />
      </main>
    </>
  );
}

export default HomePage;
