import React from "react";
import content from "../cms/content.json";
import CategoriesIcons from "./CategoriesIcons";
import Highlighter from 'react-highlight-words';

function Achievements() {
  const achievements = content.achievements;
  const status = achievements.reduce((acc, achievement) => {
    if (!acc.hasOwnProperty(achievement.estado)) {
      acc[achievement.estado] = [];
    }
    acc[achievement.estado].push(achievement);
    return acc;
  }, {});
  const groupByStatus = Object.keys(status);

  const ToBoldWord = (title, highLightedWords) => {
    if (highLightedWords) {
      return (
        <Highlighter
        textToHighlight={title}
        searchWords={highLightedWords}
        highlightStyle={{ fontWeight: 'bold' }}
        className="text-black-50"
      />);
    } else {
      return <p className="text-black-50">{title}</p>;
    }
  }
  const ToItalicWord = (observation) => {
    if (observation) {
      return (
        <span className="text-black-50" style={{ fontStyle: 'italic'}}>{observation}</span>
      );
    }
  }

  return (
    <div
      className="py-vh-5 w-100 bg-light overflow-hidden"
      id="achievements"
    >
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-lg-4">
            <h3 className="py-5 aos-init aos-animate" data-aos="fade-right">
              <hr className="shadow"></hr>
              {content.titles.achievements1}
            </h3>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-12">
                <h2
                  className="display-6 mb-5 aos-init aos-animate"
                  data-aos="fade-down"
                >
                  {content.titles.achievements2}
                </h2>
              </div>
              {groupByStatus.map((state) => (
                <div key={state} className="col-lg-12 row">
                  <h3 className="lead fw-bold py-3">{state}</h3>
                  {status[state].map((achievement) => (
                    <div className="col-lg-6" key={achievement.id}>
                      <CategoriesIcons icon={achievement.Icon} />
                      {ToBoldWord(achievement.title, achievement.highLightedWords)} {ToItalicWord(achievement.observation)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Achievements;
